.cabinet-navigation {
  padding: 120px 0 80px;
  text-align: center;
  background-image: url("#{$path-images}s8/cabinet-navigation-bg.svg");
  background-position: left bottom -230px;
  background-repeat: no-repeat;

  @include media($lg) {
    background-image: none;
  }

  @include media($md) {
    padding: 30px 0;
  }
}