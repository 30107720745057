.conditions {
  position: relative;
  padding-bottom: 116px;

  @include media($lg) {
    padding-bottom: 60px;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    background-repeat: no-repeat;

    @include media($xl) {
      display: none;
    }
  }

  &::before {
    left: 46px;
    bottom: 48px;
    width: 166px;
    height: 84px;
    background-image: url("#{$path-images}s8/conditions-left.svg");
  }

  &::after {
    right: 142px;
    top: -111px;
    width: 86px;
    height: 172px;
    background-image: url("#{$path-images}s8/conditions-right.svg");
  }

  &__title {
    margin-bottom: 32px;
    max-width: 675px;
    font-weight: 700;
    font-size: 36px;
    color: #252525;

    @include media($sm) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__items {
    display: flex;
    justify-content: space-between;

    @include media($lg) {
      flex-wrap: wrap;
    }
  }

  &__item {
    padding-left: 74px;
    background-position: left top;
    background-repeat: no-repeat;

    @include media($lg) {
      padding-right: 10px;
      width: 50%;
    }

    @include media($sm) {
      padding-right: 0;
      padding-left: 64px;
      width: 100%;
      background-size: 48px 48px;
    }

    &:not(:last-child) {
      margin-right: 100px;

      @include media($xl) {
        margin-right: 40px;
      }

      @include media($lg) {
        margin-right: 0;
      }

      @include media($sm) {
        margin-bottom: 24px;
      }
    }

    @each $icon in "results", "answer", "quest" {
      &--#{$icon} {
        background-image: url("#{$path-images}s8/#{$icon}.png");
      }
    }
  }

  &__subtitle {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 20px;
    color: #3d1f70;
  }

  &__text {
    font-size: 16px;
    color: #252525;

    @include media($sm) {
      font-size: 14px;
    }

    a {
      text-decoration: underline;

      &:hover {
        color: #3d1f70;
      }
    }
  }
}