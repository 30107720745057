.footer {
  margin-top: auto;
  padding: 46px 0 45px;
  background-image: linear-gradient(269.97deg, #6e7cfe 0.02%, #8059c7 99.97%);

  @include media($sm) {
    padding: 40px 0;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 34px;
    border-bottom: 1px solid rgba(#fff, 0.2);

    @include media($lg) {
      flex-direction: column;
      margin-bottom: 32px;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &__item {
    @include media($lg) {
      text-align: center;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    @include media($sm) {
      text-align: left;
    }
  }

  &__link {
    font-weight: 400;
    font-size: 14px;
    text-decoration-line: underline;
    color: #f4f7ff;

    @include media($lg) {
      display: block;
      line-height: 1;
    }

    &:hover {
      color: #f4f7ff;
    }
  }

  &__bottom {
    //display: flex;
    //align-items: center;
    //justify-content: space-between;
    //
    //@include media($lg) {
    //  flex-direction: column;
    //}
    //
    //@include media($sm) {
    //  align-items: flex-start;
    //}
  }

  &__wrap {
    display: flex;
    margin-bottom: 32px;

    @include media($lg) {
      margin-bottom: 0;
      flex-direction: column;
    }
  }

  &__license {
    display: flex;
    align-items: center;
    padding-left: 83px;
    min-height: 70px;
    max-width: 700px;
    margin-right: 88px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: #fff;
    background-image: url("#{$path-images}gerb.png");
    background-position: left center;
    background-repeat: no-repeat;

    @include media($lg) {
      margin-bottom: 32px;
    }

    @include media($sm) {
      padding-left: 0;
      background-image: none;
    }
  }

  &__skolkovo {
    display: flex;
    align-items: center;
    padding-left: 83px;
    max-width: 430px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.8;
    color: #fff;
    background-image: url("#{$path-images}resident.png");
    background-position: left center;
    background-repeat: no-repeat;

    @include media($lg) {
      margin-bottom: 32px;
    }

    @include media($sm) {
      padding-left: 0;
      background-image: none;
    }
  }

  &__info {
    display: flex;
    justify-content: center;

    @include media($lg) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__element {
    font-size: 14px;
    color: #f4f7ff;

    .footer__link {
      display: inline-block;
    }

    &:not(:last-child) {
      margin-right: 32px;

      @include media($lg) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}
