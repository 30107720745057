.hero {
  padding: 60px 0 194px;
  background-image: url("#{$path-images}/s8/white-bg.svg"), url("#{$path-images}/s8/main-bg.svg"), linear-gradient(90deg, #8159c6 0%, #6c7cff 100%);
  background-position: bottom, center top, center;
  background-size: 100%, auto;
  background-repeat: no-repeat;

  @include media($xl) {
    padding: 40px 0 60px;
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url("#{$path-images}s8/main-bg-2.png");
    background-position: top right;
    background-repeat: no-repeat;

    @include media($xl) {
      background-image: none;
    }

    @include media($md) {
      background-image: url("#{$path-images}s8/main-bg-2.png");
      background-position: top 129px right;
      background-size: 204px;
      background-repeat: no-repeat;
    }

    @include media(365px) {
      background-position: top 175px right;
      background-size: 204px;
    }
  }

  &__title {
    margin-bottom: 24px;
    max-width: 600px;
    font-weight: 700;
    font-size: 55px;
    line-height: 1.2;
    color: #fff;

    @include media($md) {
      margin-bottom: 36px;
      font-size: 32px;
    }
  }

  &__contribution {
    position: absolute;
    right: 474px;
    top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    background-color: #fff;
    animation-name: hovering;
    animation-duration: 3.5s;
    animation-iteration-count: infinite;

    @include media($xl) {
      right: 0;
      top: 235px;
    }

    @include media($lg) {
      width: 120px;
      height: 120px;
    }

    @include media($md) {
      position: static;
      margin-bottom: 72px;
    }

    p {
      margin-bottom: 15px;
      font-size: 34px;
      color: #ec3737;

      @include media($lg) {
        margin-bottom: 9px;
        font-size: 20px;
      }
    }

    span {
      font-size: 36px;
      color: #3d1f70;

      @include media($lg) {
        font-size: 22px;
      }
    }
  }

  &__list {
    display: flex;
    margin-bottom: 34px;

    @include media($sm) {
      flex-direction: column;
      order: 1;
      margin-bottom: 0;
    }
  }

  &__element {
    &:not(:last-child) {
      margin-right: 106px;

      @include media($lg) {
        margin-right: 50px;
      }

      @include media($sm) {
        margin-right: 0;
        margin-bottom: 32px;
      }
    }
  }

  &__text {
    margin-bottom: 11px;
    font-weight: 600;
    color: #fff;
  }

  &__item {
    padding-left: 19px;
    font-size: 14px;
    line-height: 1.4;
    color: #fff;
    background-image: url("#{$path-images}success.svg");
    background-position: left top 5px;
    background-repeat: no-repeat;

    span {
      font-weight: 600;
      color: #ffde00;
    }

    &:not(:last-child) {
      margin-bottom: 14px;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;

    @include media($sm) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 48px;

      a {
        margin-bottom: 22px;
        width: 100%;
        text-align: center;
      }
    }
  }

  &__license {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 57px;
    padding-left: 70px;
    min-height: 58px;
    max-width: 390px;
    font-size: 14px;
    color: #fff;

    @include media($sm) {
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 53px;
      height: 58px;
      background-image: url("#{$path-images}gerb.png");
      background-repeat: no-repeat;
    }
  }

  &__stop {
    color: #ffde00;

    @include media($sm) {
      margin-bottom: 15px;
    }
  }
}

@keyframes hovering {
  from {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  to {
    transform: translateY(0);
  }
}
