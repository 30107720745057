.members {
  position: relative;
  padding: 173px 0 162px;
  background-image: url("#{$path-images}s8/members-left.svg"), url("#{$path-images}s8/members-right.svg");
  background-position: left top 98px, right 160px top 21px;
  background-repeat: no-repeat;

  @include media($xl) {
    padding: 40px 0 80px;
    background-image: none;
  }

  &__container {
    position: relative;
    display: flex;

    @include media($lg) {
      flex-direction: column;
    }
  }

  &__wrap {
    width: 100%;
    max-width: 650px;

    @include media($lg) {
      margin-bottom: 32px;
      max-width: none;
    }
  }

  &__title {
    margin-bottom: 24px;
    max-width: 550px;
    font-weight: 700;
    font-size: 36px;
    color: #252525;

    @include media($sm) {
      font-size: 24px;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 32px;
    max-width: 625px;
  }

  &__item {
    position: relative;
    display: inline-block;
    padding-left: 38px;
    font-size: 30px;
    color: #252525;

    @include media($sm) {
      padding-left: 31px;
      font-size: 16px;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 10px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-image: url("#{$path-images}success.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-color: #7ccc7f;

      @include media($sm) {
        top: 0;
      }
    }

    &:not(:last-child) {
      margin-right: 64px;
      margin-bottom: 24px;

      @include media($sm) {
        margin-right: 24px;
      }
    }
  }

  &__description {
    max-width: 460px;
    font-size: 18px;
    color: #5c5c5c;

    @include media($sm) {
      font-size: 14px;
    }
  }

  &__img {
    margin-right: -125px;

    @include media(1500px) {
      margin-right: 0;
    }

    img {
      object-fit: contain;

      @include media($md) {
        height: auto;
      }
    }
  }
}