.header {
  margin-bottom: auto;
  padding: 34px 0 24px;
  background-image: linear-gradient(90deg, #8159c6 0%, #6c7cff 100%);

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media($xl) {
      flex-wrap: wrap;
    }
  }

  &__items {
    display: flex;

    @include media($xl) {
      order: 1;
      margin: 0 auto;
    }

    @include media($sm) {
      flex-wrap: wrap;
    }
  }

  &__item {
    @include media($sm) {
      flex-wrap: wrap;
      margin-top: 24px;
      width: 50%;
    }

    &:not(:last-child) {
      margin-right: 56px;

      @include media($sm) {
        margin-right: 0;
      }
    }
  }

  &__pretitle {
    font-size: 14px;
    color: #fff;
  }

  &__date {
    font-weight: 600;
    font-size: 20px;
    color: #ffde00;
  }

  &__wrap {
    position: relative;
    display: flex;
    align-items: center;

    @include media($sm) {
      justify-content: flex-end;
      width: 50%;
    }
  }

  &__block {
    display: flex;
    align-items: center;
  }

  &__applications {
    margin-right: 10px;

    @include media($sm) {
      display: none;
    }
  }

  &__icon {
    position: relative;
    display: block;
    padding-right: 25px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      right: 5px;
      top: 50%;
      display: inline-block;
      padding: 4px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform:
        translateY(calc(-50% - 5px)) rotate(
          45deg
        );
      transition-property: transform;
      transition-duration: 300ms;
    }
  }

  &__list {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 3;
    display: none;
    margin: 0;
    padding: 10px 0;
    width: 100%;
    border-radius: 10px;
    font-size: 14px;
    background-color: #fff;

    a {
      display: block;
      padding: 8px 15px;
      color: #000;

      &:hover {
        text-decoration: none;
        color: #fff;
        background: #5ab0f5;
      }
    }
  }

  .logo {
    @include media($sm) {
      width: 125px;

      img {
        vertical-align: middle;
      }
    }

    @include media(340px) {
      width: 100px;
    }

    img {
      vertical-align: top;
    }
  }
}
