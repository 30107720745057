@import "core/reset";
@import "helpers/variables";
@import "helpers/mixins";
@import "core/global";

// components
@import "components/header";
@import "components/footer";
@import "components/hero";
@import "components/start";
@import "components/members";
@import "components/conditions";
@import "components/certificates";
@import "components/authorization";
@import "components/cabinet-navigation";

// @import "components/style-main";
.a-load-button {
  background-image:
    linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.3) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0.3) 75%,
      transparent 75%,
      transparent
    ) !important;
  background-size: 40px 40px !important;
  background-color: #ffde00 !important;
  animation: progress-bar-stripes 2s linear infinite;
  cursor: no-drop;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}