.authorization {
  position: relative;
  padding: 117px 0 136px;
  overflow: hidden;

  @include media($sm) {
    padding: 64px 0 80px;
  }

  &::before {
    content: "";
    position: absolute;
    left: -174px;
    bottom: -457px;
    width: 546px;
    height: 546px;
    border-radius: 50%;
    background-color: #27affd;

    @include media($sm) {
      display: none;
    }
  }

  &__title {
    margin-bottom: 36px;
    font-weight: 700;
    font-size: 36px;
    color: #252525;

    @include media($sm) {
      margin-bottom: 24px;
      font-size: 24px;
    }
  }

  &__element {
    &:not(:last-child) {
      margin-bottom: 73px;
    }
  }

  &__message {
    margin-bottom: 16px;
    color: #eb5757;

    p {
      display: none;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -16px;

    @include media($xl) {
      margin: 0 -8px;
    }
  }

  &__item {
    margin: 0 16px 24px;
    width: calc(100% / 3 - 32px);
    height: 58px;

    @include media($xl) {
      margin: 0 8px 12px;
      width: calc(100% / 3 - 16px);
    }

    @include media($lg) {
      width: calc(100% / 2 - 16px);
    }

    @include media($sm) {
      margin: 0 8px 8px;
      width: calc(100% / 1 - 16px);
    }

    &--more {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media($xl) {
        flex-direction: column;

        input {
          margin-bottom: 16px;
          width: 100%;
          text-align: center;
        }
      }
    }
  }

  &__input {
    padding: 18px 26px;
    width: 100%;
    height: 100%;
    border: 1px solid #aaa;
    border-radius: 4px;
    font-size: 18px;
    color: #5c5c5c;
    background-color: #fff;

    &::placeholder {
      color: #aaa;
    }
  }
}