@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
  color: #252525;
  background-color: #fff;
}

.container {
  margin: 0 auto;
  padding: 0 10px;
  width: 100% !important;
  max-width: 1240px !important;
}

.button {
  display: inline-block;
  border: none;
  font-size: 16px;
  color: #252525;
  cursor: pointer;

  &:hover {
    color: #252525;
  }

  &--white {
    padding: 13px 32px;
    border-radius: 40px;
    background-color: #fff;
    transition: background-color 0.5s;

    &:hover {
      background-color: #ffde00;
    }
  }

  &--yellow {
    padding: 18px 40px;
    border-radius: 4px;
    background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
    background-size: 400% 100%;
    transition: background-size 0.3s;

    &:hover {
      box-shadow: 0 4px 10px rgba(148, 124, 4, 0.32);
      background-image: linear-gradient(87.61deg, #ffde00 0%, #ff8934 100%);
      background-size: 100% 100%;
    }

    &:active {
      box-shadow: inset 0 0 4px #947c04;
    }
  }

  &--100 {
    width: 100%;
    text-align: center;
  }
}

.link {
  font-weight: 400;
  font-size: 18px;
  text-decoration: underline;
  color: #aaa;

  &:hover {
    color: #3d1f70;
  }
}