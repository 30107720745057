.certificates {
  padding: 72px 0 48px;
  overflow: hidden;
  background-image:
    url("#{$path-images}s8/certificates-left.svg"),
    url("#{$path-images}s8/certificates-right.svg"),
    linear-gradient(90deg, #8159c6 0%, #6c7cff 100%);
  background-position: left bottom 50px, right bottom, center;
  background-repeat: no-repeat;

  @include media($xl) {
    padding: 40px 0  50px;
    background-image: linear-gradient(90deg, #8159c6 0%, #6c7cff 100%);
    background-position: center;
  }

  &__swiper {
    margin-bottom: 72px;
    padding: 0 70px;
    user-select: none;

    @include media(440px) {
      padding: 0 100px;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    margin-bottom: 72px;

    @include media($lg) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 64px;
    }
  }

  &__title {
    margin-right: 40px;
    max-width: 790px;
    font-weight: 700;
    font-size: 36px;
    color: #fff;

    @include media($xl) {
      max-width: 674px;
    }

    @include media($lg) {
      margin-right: 0;
      margin-bottom: 24px;
      max-width: none;
    }

    @include media($sm) {
      font-size: 24px;
    }

    span {
      text-transform: uppercase;
      color: #ffde00;
    }
  }

  &__receive {
    @include media($sm) {
      width: 100%;
      text-align: center;
    }
  }

  .swiper-slide-active {
    img {
      transform: scale(1.1);
      transition: transform 0.5s;
    }
  }

  &__link {
    display: flex;
  }

  &__stop {
    margin-bottom: 40px;
    text-align: center;
    color: #ffde00;
  }

  &__picture {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    filter: drop-shadow(0 6px 24px rgba(107, 44, 136, 0.3));
  }

  &__navigation {
    display: flex;
    justify-content: center;
  }

  &__button {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    background-image: url("#{$path-images}arrow-slider.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;

    &:not(:last-child) {
      margin-right: 16px;
      transform: rotate(180deg);
    }
  }
}