.start {
  &__container {
    display: flex;
    padding-top: 138px;
    padding-bottom: 40px;
    background-image: url("#{$path-images}s8/start-top.svg");
    background-position: top left;
    background-repeat: no-repeat;

    @include media($md) {
      flex-direction: column;
    }

    @include media($sm) {
      padding-top: 60px;
      background-image: none;
    }
  }

  &__img {
    margin-top: -40px;
    margin-left: -351px;

    @include media(1500px) {
      margin-left: 0;
    }

    @include media($md) {
      margin-bottom: 32px;
    }

    img {
      object-fit: contain;

      @include media($md) {
        height: auto;
      }
    }
  }

  &__wrap {
    margin-left: auto;
    max-width: 492px;

    @include media($md) {
      margin-left: 0;
      max-width: none;
    }
  }

  &__title {
    margin-bottom: 51px;
    font-weight: 400;
    font-size: 24px;
    color: #252525;

    @include media($md) {
      margin-bottom: 24px;
    }

    @include media($sm) {
      font-size: 16px;
    }

    span {
      font-weight: 600;
      color: #3d1f70;
    }
  }

  &__text {
    margin-bottom: 16px;
    max-width: 370px;
    color: #252525;

    @include media($md) {
      margin-bottom: 8px;
      max-width: none;
    }

    @include media($sm) {
      font-size: 16px;
    }
  }

  &__counter {
    font-weight: 700;
    font-size: 36px;
    line-height: 1.4;
    color: #3d1f70;

    @include media($sm) {
      font-size: 30px;
    }
  }
}